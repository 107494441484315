import React from "react";

const Skills = () => {
  return (
    <>
      <div
        class=" skills-card mb-8 p-3 md:px-10 md:py-5 bg-kjColorLight dark:bg-kjColorBlack border-2 border-kjColorLight "
        data-v-cb12b778=""
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/v1653464815/kenjimmy.me/html_sx3o4c.png"
            alt="html"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/v1653464822/kenjimmy.me/css_llfioq.png"
            alt="css"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>

        <div class="w-16 inline-block brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653463796/kenjimmy.me/kisspng-angularjs-typescript-javascript-vue-js-5b342607e4f524.2219525715301442639378_jgy8fc.png"
            alt="typescript"
            class="h-12 inline "
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/v1653464815/kenjimmy.me/javascript_wpr45g.png"
            alt="javascript"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653559880/kenjimmy.me/kisspng-game-react-native-javascript-android-physics-5ac6d5f51879e8.6623465115229803411003_ugk9af.png"
            alt="react"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>

        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/next-js.svg"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/redux.svg"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/redux-saga.svg"
            alt="postman"
            class="h-12 "
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://iconape.com/wp-content/png_logo_vector/jest-logo.png"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://testing-library.com/img/logo-large.png"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/react-native-1.svg"
            alt="postman"
            class="h-12 "
            data-v-cb12b778=""
          />
        </div>

        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/v1653464817/kenjimmy.me/sass_zt364q.png"
            alt="sass"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/bootstrap-5-1.svg"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://cdn.worldvectorlogo.com/logos/material-ui-1.svg"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 inline-block mb-2 brand" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/v1653464819/kenjimmy.me/tailwindcss_xvxlaw.png"
            alt="tailwindcss"
            class="h-12 inline brand__img"
            data-v-cb12b778=""
          />
        </div>

        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653464815/kenjimmy.me/git_fmjpe4.png"
            alt="git"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjE5MiIgdmlld0JveD0iMCAwIDI1IDI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGQ9Im0yNC41MDcgOS41LS4wMzQtLjA5TDIxLjA4Mi41NjJhLjg5Ni44OTYgMCAwIDAtMS42OTQuMDkxbC0yLjI5IDcuMDFINy44MjVMNS41MzUuNjUzYS44OTguODk4IDAgMCAwLTEuNjk0LS4wOUwuNDUxIDkuNDExLjQxNiA5LjVhNi4yOTcgNi4yOTcgMCAwIDAgMi4wOSA3LjI3OGwuMDEyLjAxLjAzLjAyMiA1LjE2IDMuODY3IDIuNTYgMS45MzUgMS41NTQgMS4xNzZhMS4wNTEgMS4wNTEgMCAwIDAgMS4yNjggMGwxLjU1NS0xLjE3NiAyLjU2LTEuOTM1IDUuMTk3LTMuODkuMDE0LS4wMUE2LjI5NyA2LjI5NyAwIDAgMCAyNC41MDcgOS41WiIKICAgICAgICBmaWxsPSIjRTI0MzI5Ii8+CiAgPHBhdGggZD0ibTI0LjUwNyA5LjUtLjAzNC0uMDlhMTEuNDQgMTEuNDQgMCAwIDAtNC41NiAyLjA1MWwtNy40NDcgNS42MzIgNC43NDIgMy41ODQgNS4xOTctMy44OS4wMTQtLjAxQTYuMjk3IDYuMjk3IDAgMCAwIDI0LjUwNyA5LjVaIgogICAgICAgIGZpbGw9IiNGQzZEMjYiLz4KICA8cGF0aCBkPSJtNy43MDcgMjAuNjc3IDIuNTYgMS45MzUgMS41NTUgMS4xNzZhMS4wNTEgMS4wNTEgMCAwIDAgMS4yNjggMGwxLjU1NS0xLjE3NiAyLjU2LTEuOTM1LTQuNzQzLTMuNTg0LTQuNzU1IDMuNTg0WiIKICAgICAgICBmaWxsPSIjRkNBMzI2Ii8+CiAgPHBhdGggZD0iTTUuMDEgMTEuNDYxYTExLjQzIDExLjQzIDAgMCAwLTQuNTYtMi4wNUwuNDE2IDkuNWE2LjI5NyA2LjI5NyAwIDAgMCAyLjA5IDcuMjc4bC4wMTIuMDEuMDMuMDIyIDUuMTYgMy44NjcgNC43NDUtMy41ODQtNy40NDQtNS42MzJaIgogICAgICAgIGZpbGw9IiNGQzZEMjYiLz4KPC9zdmc+Cg=="
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653464815/kenjimmy.me/github_cctzbu.png"
            alt="github"
            class="h-12 "
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653464815/kenjimmy.me/npm_c4lqfw.png"
            alt="npm"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
        <div class="w-16 brand md:mb-4" data-v-cb12b778="">
          <img
            src="https://res.cloudinary.com/dwa1jtluu/image/upload/q_auto,f_auto/v1653464818/kenjimmy.me/postman_thyot5.png"
            alt="postman"
            class="h-12 brand__img"
            data-v-cb12b778=""
          />
        </div>
      </div>
    </>
  );
};

export default Skills;
