import React from "react";

import heroImg from "../../assets/img/hero/mhl.jpeg";
import heroImgMobile from "../../assets/img/hero/mhl-mobile.jpg";

const heroContent = {
  heroImage: heroImg,
  heroMobileImage: heroImgMobile,
  heroTitleName: "MH LASHGARI",
  heroDesignation: "Front-end Developer",
  heroDescriptions: `I'm a front-end developer with a passion for creating dynamic and user-friendly web applications. I have more than three years of experience in React and Next.js, and I'm well-versed in modern front-end frameworks and libraries. I'm a strong team player with great problem-solving skills and a keen eye for detail.",`,
};

const Hero = () => {
  return (
    <>
      <div className="row home-details-container align-items-center">
        <div
          className="col-lg-4 bg position-fixed d-none d-lg-block"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + heroContent.heroImage
            })`,
          }}
        ></div>
        <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
          <div>
            <img
              src={heroContent.heroMobileImage}
              className="img-fluid main-img-mobile d-sm-block d-lg-none"
              alt="hero man"
            />
            <h1 className="text-uppercase poppins-font">
              I'm {heroContent.heroTitleName}.
              <span>{heroContent.heroDesignation}</span>
            </h1>
            <p className="open-sans-font">{heroContent.heroDescriptions}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
